var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "video-grid-item",
      class: { selected: _vm.isSelectionMode && _vm.item.selected },
      style: { zIndex: _vm.item.hover ? 10 : "auto" },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.toggleSelection($event)
        },
        mouseover: _vm.mouseoverItem,
        mouseleave: _vm.mouseleaveItem,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "left-section video-grid-bg flex-center",
        },
        [
          _vm.item.fileType == "image"
            ? _c("div", { staticClass: "video-grid-cover " }, [
                _c("img", {
                  staticClass: "video-grid-cover",
                  attrs: { src: _vm.item.url, alt: "image" },
                }),
              ])
            : _vm.item.fileType == "audio"
            ? _c(
                "div",
                { staticClass: "video-grid-cover video-grid-bg flex-center" },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/images/video/audio.png"),
                      alt: "",
                    },
                  }),
                  _vm.isPlaying
                    ? _c("div", { staticClass: "video-audio-playing" }, [
                        _c("img", {
                          attrs: {
                            src: require("../../assets/images/video/audio-playing.gif"),
                            alt: "",
                          },
                        }),
                        _vm._v(" 试听中... "),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm.item.fileType == "text"
            ? _c(
                "div",
                { staticClass: "video-grid-cover video-grid-bg flex-center" },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/images/video/text.png"),
                      alt: "",
                    },
                  }),
                ]
              )
            : _c("div", { staticClass: "video-grid-cover" }, [
                _c("video", {
                  ref: "videoPlayer",
                  staticClass: "video-grid-cover",
                  attrs: {
                    src: _vm.item.url,
                    alt: "video",
                    muted: "",
                    playsinline: "",
                    loop: "",
                    preload: "auto",
                  },
                  domProps: { muted: true },
                  on: { mouseenter: _vm.playVideo, mouseleave: _vm.pauseVideo },
                }),
                _vm.item.videoLength && _vm.item.videoLength > 0
                  ? _c("span", { staticClass: "video-grid-duration" }, [
                      _vm._v(
                        _vm._s(_vm.secondsFormatTime(_vm.item.videoLength))
                      ),
                    ])
                  : _vm._e(),
                !_vm.isFailed &&
                _vm.splitStatus === _vm.$t("videoItem.clipSuccess")
                  ? _c(
                      "div",
                      {
                        staticClass: "video-grid-clip",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.splitDetail(_vm.item)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("videoItem.clipDetails")) + " "
                        ),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "video-grid-play",
                    on: {
                      click: function ($event) {
                        return _vm.viewMedia(_vm.item)
                      },
                    },
                  },
                  [_c("i", { staticClass: "iconfont icon-bofang1" })]
                ),
              ]),
          _vm.item.jobError
            ? _c("el-tooltip", { attrs: { content: _vm.item.jobError } }, [
                _vm.item.splitVideoPartsStatus ||
                _vm.item.mergeVideoFaceStatus ||
                _vm.item.eraseVideoSubtitlesStatus ||
                _vm.item.jobStatus
                  ? _c(
                      "div",
                      {
                        staticClass: "controlsStatus",
                        class: [_vm.filterState(_vm.item)],
                      },
                      [_vm._v(_vm._s(_vm.statusMessage))]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "middle-section" },
        [
          _c("div", { staticClass: "material-title flex-row-center " }, [
            _c("i", {
              class: [
                "iconfont",
                _vm.getIcon(_vm.item.fileType),
                "mr8",
                "f-shrink",
              ],
            }),
            _c("div", { staticClass: "f-grow single-line-ellipsis" }, [
              _vm._v(
                _vm._s(
                  _vm.item.fileType == "text" ? _vm.item.title : _vm.item.name
                )
              ),
            ]),
          ]),
          _vm.getLabels(_vm.item.label)
            ? _c(
                "el-tooltip",
                { attrs: { content: _vm.getLabels(_vm.item.label) } },
                [
                  _c(
                    "div",
                    { staticClass: "video-grid-labels single-line-ellipsis" },
                    [_vm._v(" " + _vm._s(_vm.getLabels(_vm.item.label)) + " ")]
                  ),
                ]
              )
            : _c("div", { staticClass: "video-grid-labels" }),
          _vm.item.hover && !_vm.loading
            ? _c("div", [
                _vm.item.fileType == "text"
                  ? _c(
                      "div",
                      { staticClass: "flex-center right-section" },
                      [
                        _c(
                          "el-tooltip",
                          { attrs: { content: _vm.$t("view") } },
                          [
                            _c("i", {
                              staticClass:
                                "iconfont icon-a-xialajiantoubeifen3",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.showText($event)
                                },
                              },
                            }),
                          ]
                        ),
                        _vm.item.type !== "system" && !_vm.isSelectionMode
                          ? _c(
                              "el-tooltip",
                              { attrs: { content: _vm.$t("edit") } },
                              [
                                _c("i", {
                                  staticClass: "iconfont icon-edit",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.$emit("edit-text", _vm.item)
                                    },
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm.item.type !== "system" && !_vm.isSelectionMode
                          ? _c(
                              "el-tooltip",
                              { attrs: { content: _vm.$t("remove") } },
                              [
                                _c("i", {
                                  staticClass: "iconfont icon-delete",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.deleteVideo($event)
                                    },
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "flex-center right-section" },
                      [
                        _vm.type == 3 &&
                        _vm.item.publishSuccessCount == 0 &&
                        !_vm.isSelectionMode
                          ? _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content: _vm.$t("videoManager.publish"),
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "iconfont icon-xingzhuangjiehe",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.publish(_vm.item)
                                    },
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm.item.fileType == "image"
                          ? _c(
                              "div",
                              [
                                !_vm.isFailed
                                  ? _c(
                                      "el-tooltip",
                                      { attrs: { content: _vm.$t("view") } },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "iconfont icon-a-xialajiantoubeifen3",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.viewMedia(_vm.item)
                                            },
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm.item.fileType == "audio"
                          ? _c(
                              "div",
                              [
                                !_vm.isFailed
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          content: _vm.$t("file.filePreview"),
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "iconfont icon-erji",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.playAudio()
                                            },
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                !_vm.isFailed
                                  ? _c(
                                      "el-tooltip",
                                      { attrs: { content: _vm.$t("view") } },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "iconfont icon-a-xialajiantoubeifen3",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.$emit(
                                                "view-text",
                                                _vm.item
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                        !_vm.isFailed &&
                        _vm.item.type !== "system" &&
                        !_vm.isSelectionMode
                          ? _c(
                              "el-tooltip",
                              { attrs: { content: _vm.$t("edit") } },
                              [
                                _c("i", {
                                  staticClass: "iconfont icon-edit",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.$emit("edit-video", _vm.item)
                                    },
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        !_vm.isFailed &&
                        _vm.item.type !== "system" &&
                        !_vm.isSelectionMode
                          ? _c(
                              "el-tooltip",
                              { attrs: { content: _vm.$t("file.download") } },
                              [
                                _c("i", {
                                  staticClass: "iconfont icon-daoru",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.downloadFile(_vm.item.url)
                                    },
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        !_vm.isFailed &&
                        _vm.item.type !== "system" &&
                        !_vm.isSelectionMode
                          ? _c(
                              "el-tooltip",
                              { attrs: { content: _vm.$t("copyLink") } },
                              [
                                _c("i", {
                                  staticClass:
                                    "iconfont icon-lianjiebeidianjiliang",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.copyLink($event)
                                    },
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm.item.type !== "system" && !_vm.isSelectionMode
                          ? _c(
                              "el-tooltip",
                              { attrs: { content: _vm.$t("remove") } },
                              [
                                _c("i", {
                                  staticClass: "iconfont icon-delete",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.deleteVideo($event)
                                    },
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm.dialogVisible
        ? _c("VideoButtonGrid", {
            attrs: {
              dialogVisible: _vm.dialogVisible,
              video: _vm.mediaItem,
              type: 1,
              tip: _vm.$t("file.filePreview"),
            },
            on: { closeMedia: _vm.closeMediaFun },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
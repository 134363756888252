var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "0 15px" }, on: { click: _vm.toggleClick } },
    [_c("i", { staticClass: "iconfont icon-zhankai hamburger" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
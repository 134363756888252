var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            "header-row-class-name": "custom_table_header",
            height: "calc(100vh - 350px)",
            data: _vm.list,
          },
          on: {
            "selection-change": _vm.handleSelectionChange,
            "cell-mouse-enter": _vm.cellMouseEnter,
            "cell-mouse-leave": _vm.cellMouseLeave,
          },
        },
        [
          _c("el-empty", {
            attrs: { slot: "empty", "image-size": 180 },
            slot: "empty",
          }),
          _c("el-table-column", {
            attrs: {
              type: "selection",
              width: "55",
              align: "center",
              selectable: _vm.checkSelectable,
            },
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("file.name"), prop: "url", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "flex-row-center" }, [
                      scope.row.documentType == "folder"
                        ? _c("div", [
                            scope.row.type === "system"
                              ? _c("img", {
                                  staticClass: "img-forder-list",
                                  attrs: {
                                    src: require("../../assets/images/folder-system.png"),
                                    alt: "",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "img-forder-list",
                                  attrs: {
                                    src: require("../../assets/images/folder-user.png"),
                                    alt: "",
                                  },
                                }),
                          ])
                        : _c("div", [
                            scope.row.fileType == "image"
                              ? _c("div", [
                                  _c("img", {
                                    staticClass: "video-cover",
                                    attrs: { src: scope.row.url, alt: "image" },
                                  }),
                                ])
                              : scope.row.fileType == "text"
                              ? _c("div", [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../assets/images/video/text.png"),
                                      alt: "",
                                      width: "30px",
                                      height: "30px",
                                    },
                                  }),
                                ])
                              : scope.row.fileType == "audio"
                              ? _c("div", [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../assets/images/video/audio.png"),
                                      alt: "",
                                      width: "30px",
                                      height: "30px",
                                    },
                                  }),
                                ])
                              : _c("div", [
                                  _c("video", {
                                    staticClass: "video-cover",
                                    attrs: { src: scope.row.url, alt: "video" },
                                  }),
                                ]),
                          ]),
                      _c(
                        "span",
                        {
                          staticClass: "single-line-ellipsis ml5 material-name",
                          on: {
                            click: function ($event) {
                              return _vm.nameClick(scope.row)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                scope.row.fileType == "text"
                                  ? scope.row.title
                                  : scope.row.name
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("label.label"),
              align: "center",
              prop: "labels",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.labels, function (tag, index) {
                    return _c(
                      "el-tag",
                      {
                        key: index,
                        staticStyle: {
                          "margin-right": "5px",
                          "margin-bottom": "5px",
                        },
                      },
                      [_vm._v(" " + _vm._s(tag) + " ")]
                    )
                  })
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("file.format"),
              align: "center",
              prop: "suffix",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { style: scope.row.hover ? "opacity: 0" : "opacity: 1" },
                      [_vm._v(" " + _vm._s(scope.row.suffix) + " ")]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: scope.row.hover,
                            expression: "scope.row.hover",
                          },
                        ],
                        staticClass: "flex-row-center operate-hover",
                      },
                      [
                        scope.row.documentType == "folder"
                          ? _c("div", { staticClass: "flex-row-center" }, [
                              scope.row.type !== "system"
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex-row-center list-button",
                                      on: {
                                        click: function ($event) {
                                          return _vm.$emit(
                                            "edit-folder",
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "iconfont icon-edit",
                                      }),
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("edit")) + " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              scope.row.type !== "system"
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex-row-center list-button",
                                      on: {
                                        click: function ($event) {
                                          return _vm.$emit(
                                            "delete-folder",
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "iconfont icon-delete",
                                      }),
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("remove")) + " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          : _c("div", [
                              scope.row.fileType == "text"
                                ? _c(
                                    "div",
                                    { staticClass: "flex-row-center" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-row-center list-button",
                                          on: {
                                            click: function ($event) {
                                              return _vm.$emit(
                                                "view-text",
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "iconfont icon-chakan",
                                          }),
                                          _vm._v(
                                            " " + _vm._s(_vm.$t("view")) + " "
                                          ),
                                        ]
                                      ),
                                      scope.row.type !== "system"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex-row-center list-button",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$emit(
                                                    "edit-text",
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "iconfont icon-edit",
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("edit")) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      scope.row.type !== "system"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex-row-center list-button",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$emit(
                                                    "delete-video",
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "iconfont icon-delete",
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("remove")) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "flex-row-center" },
                                    [
                                      _vm.type == 3 &&
                                      scope.row.publishSuccessCount == 0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex-row-center list-button",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.publish(scope.row)
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "iconfont icon-xingzhuangjiehe",
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "videoManager.publish"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      !_vm.isFailed(scope.row) &&
                                      _vm.splitStatus(scope.row) ===
                                        _vm.$t("videoItem.clipSuccess")
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex-row-center list-button",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.splitDetail(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-more",
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "videoItem.clipDetails"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      !_vm.isFailed(scope.row)
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "ml10 mr10",
                                              staticStyle: {
                                                display: "inline-block",
                                              },
                                            },
                                            [
                                              _c(
                                                "video-button",
                                                {
                                                  attrs: {
                                                    type: 2,
                                                    video: scope.row,
                                                    tip: _vm.$t(
                                                      "file.filePreview"
                                                    ),
                                                  },
                                                  on: {
                                                    closeViewVideo:
                                                      _vm.closeViewVideo,
                                                    viewVideo: _vm.viewVideo,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "customButton" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "flex-row-center list-button",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "iconfont icon-chakan",
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "file.filePreview"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !_vm.isFailed(scope.row)
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex-row-center list-button",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$emit(
                                                    "edit-video",
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "iconfont icon-edit",
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("edit")) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      !_vm.isFailed(scope.row) &&
                                      scope.row.type !== "system"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex-row-center list-button",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.downloadFile(
                                                    scope.row.url
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "iconfont icon-daoru",
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("file.download")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      !_vm.isFailed(scope.row) &&
                                      scope.row.type !== "system"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex-row-center list-button",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.copyLink(scope.row)
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "iconfont icon-lianjiebeidianjiliang",
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("copyLink")) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      scope.row.type !== "system"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex-row-center list-button",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$emit(
                                                    "delete-video",
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "iconfont icon-delete",
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("remove")) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                            ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("time.createTime"),
              align: "center",
              prop: "createTime",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { style: scope.row.hover ? "opacity: 0" : "opacity: 1" },
                      [_vm._v(" " + _vm._s(scope.row.createTime) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("file.fileSize"),
              align: "center",
              prop: "fileSize",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { style: scope.row.hover ? "opacity: 0" : "opacity: 1" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.fileSize
                                ? _vm.formatFileSize(scope.row.fileSize)
                                : "--"
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <el-table
      v-loading="loading"
      header-row-class-name="custom_table_header"
      height="calc(100vh - 350px)"
      :data="list"
      @selection-change="handleSelectionChange"
      @cell-mouse-enter="cellMouseEnter"
      @cell-mouse-leave="cellMouseLeave"
    >
      <el-empty
        :image-size="180"
        slot="empty"
      ></el-empty>
      <el-table-column
        type="selection"
        width="55"
        align="center"
        :selectable="checkSelectable"
      />
      <el-table-column
        :label="$t('file.name')"
        prop="url"
        width="200"
      >
        <template slot-scope="scope">
          <div class="flex-row-center">
            <div v-if="scope.row.documentType == 'folder'">
              <img
                src="../../assets/images/folder-system.png"
                alt=""
                class="img-forder-list"
                v-if="scope.row.type === 'system'"
              >
              <img
                src="../../assets/images/folder-user.png"
                alt=""
                class="img-forder-list"
                v-else
              >
            </div>
            <div v-else>
              <div v-if="scope.row.fileType == 'image'">
                <img
                  :src="scope.row.url"
                  alt="image"
                  class="video-cover"
                />
              </div>
              <div v-else-if="scope.row.fileType == 'text'">
                <img
                  src="../../assets/images/video/text.png"
                  alt=""
                  width="30px"
                  height="30px"
                >
              </div>
              <div v-else-if="scope.row.fileType == 'audio'">
                <img
                  src="../../assets/images/video/audio.png"
                  alt=""
                  style=""
                  width="30px"
                  height="30px"
                >
                <!-- <svg-icon class="video-cover" icon-class="audio"/> -->
              </div>
              <div v-else>
                <video
                  :src="scope.row.url"
                  alt="video"
                  class="video-cover"
                />
              </div>
            </div>
            <span
              class="single-line-ellipsis ml5 material-name"
              @click="nameClick(scope.row)"
            >
              {{ scope.row.fileType == 'text' ? scope.row.title :scope.row.name }}
            </span>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="标题" align="center" prop="name" /> -->
      <!-- <el-table-column label="时长" align="center" prop="videoLength">
        <template slot-scope="scope">
          {{ scope.row.videoLength && scope.row.videoLength>0?secondsFormatTime(scope.row.videoLength):'--' }}
        </template>
      </el-table-column> -->
      <el-table-column
        :label="$t('label.label')"
        align="center"
        prop="labels"
      >
        <template slot-scope="scope">
          <!-- <div class="video-tags font14"> -->
          <el-tag
            v-for="(tag, index) in scope.row.labels"
            :key="index"
            style="margin-right: 5px;margin-bottom: 5px;"
          >
            {{ tag }}
          </el-tag>
          <!-- </div> -->
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('file.format')"
        align="center"
        prop="suffix"
      >
        <template slot-scope="scope">
          <div :style="scope.row.hover ? 'opacity: 0' : 'opacity: 1'">
            {{ scope.row.suffix }}
          </div>
          <div
            v-show="scope.row.hover"
            class="flex-row-center operate-hover"
          >
            <div
              v-if="scope.row.documentType == 'folder'"
              class="flex-row-center"
            >
              <div
                v-if="scope.row.type !== 'system'"
                class="flex-row-center list-button"
                @click="$emit('edit-folder',scope.row)"
              >
                <i class="iconfont icon-edit"></i>
                {{ $t('edit') }}
              </div>
              <div
                v-if="scope.row.type !== 'system'"
                class="flex-row-center list-button"
                @click="$emit('delete-folder',scope.row)"
              >
                <i class="iconfont icon-delete"></i>
                {{ $t('remove') }}
              </div>
            </div>
            <div v-else>
              <div
                v-if="scope.row.fileType == 'text'"
                class="flex-row-center"
              >
                <div
                  class="flex-row-center list-button"
                  @click="$emit('view-text',scope.row)"
                >
                  <i class="iconfont icon-chakan"></i>
                  {{ $t('view') }}
                </div>
                <div
                  v-if="scope.row.type !== 'system'"
                  class="flex-row-center list-button"
                  @click="$emit('edit-text',scope.row)"
                >
                  <i class="iconfont icon-edit"></i>
                  {{ $t('edit') }}
                </div>
                <div
                  v-if="scope.row.type !== 'system'"
                  class="flex-row-center list-button"
                  @click="$emit('delete-video',scope.row)"
                >
                  <i class="iconfont icon-delete"></i>
                  {{ $t('remove') }}
                </div>
              </div>
              <div
                v-else
                class="flex-row-center"
              >
                <div
                  v-if="type == 3 && scope.row.publishSuccessCount == 0"
                  class="flex-row-center list-button"
                  @click="publish(scope.row)"
                >
                  <i class="iconfont icon-xingzhuangjiehe"></i>
                  {{ $t('videoManager.publish') }}
                </div>
                <div
                  v-if="!isFailed(scope.row) && splitStatus(scope.row) === $t('videoItem.clipSuccess')"
                  class="flex-row-center list-button"
                  @click="splitDetail(scope.row)"
                >
                  <i class="el-icon-more"></i>
                  {{ $t('videoItem.clipDetails') }}
                </div>

                <span
                  v-if="!isFailed(scope.row)"
                  style="display: inline-block;"
                  class="ml10 mr10"
                >
                  <video-button
                    :type="2"
                    :video="scope.row"
                    :tip="$t('file.filePreview')"
                    @closeViewVideo="closeViewVideo"
                    @viewVideo="viewVideo"
                  >
                    <template slot="customButton">
                      <div class="flex-row-center list-button">
                        <i class="iconfont icon-chakan"></i>
                        {{ $t('file.filePreview') }}
                      </div>
                    </template></video-button>
                </span>
                <div
                  v-if="!isFailed(scope.row)"
                  class="flex-row-center list-button"
                  @click="$emit('edit-video',scope.row)"
                >
                  <i class="iconfont icon-edit"></i>
                  {{ $t('edit') }}
                </div>
                <div
                  v-if="!isFailed(scope.row) && scope.row.type !== 'system'"
                  class="flex-row-center list-button"
                  @click="downloadFile(scope.row.url)"
                >
                  <i class="iconfont icon-daoru"></i>
                  {{ $t('file.download') }}
                </div>
                <div
                  v-if="!isFailed(scope.row) && scope.row.type !== 'system'"
                  class="flex-row-center list-button"
                  @click="copyLink(scope.row)"
                >
                  <i class="iconfont icon-lianjiebeidianjiliang"></i>
                  {{ $t('copyLink') }}
                </div>
                <div
                  v-if="scope.row.type !== 'system'"
                  class="flex-row-center list-button"
                  @click="$emit('delete-video',scope.row)"
                >
                  <i class="iconfont icon-delete"></i>
                  {{ $t('remove') }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('time.createTime')"
        align="center"
        prop="createTime"
      >
        <template slot-scope="scope">
          <div :style="scope.row.hover ? 'opacity: 0' : 'opacity: 1'">
            {{ scope.row.createTime }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('file.fileSize')"
        align="center"
        prop="fileSize"
      >
        <template slot-scope="scope">
          <div :style="scope.row.hover ? 'opacity: 0' : 'opacity: 1'">
            {{ scope.row.fileSize ? formatFileSize(scope.row.fileSize) : '--' }}
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column
        :label="$t('operate')"
        align="center"
        class-name="small-padding fixed-width"
        width="300"
      >
      </el-table-column> -->
    </el-table>

    <!-- 中间：视频信息 -->
    <!-- <div class="middle-section">
      <div class="video-created font14" >{{ video.createTime }}</div>
      <div class="video-status font14">{{ statusMessage }}</div>
      <div class="video-split font14">{{ splitStatus }}</div> -->
    <!-- </div> -->

    <!-- 右侧：操作按钮 -->
    <!-- <div class="right-section" v-if="(!isSelectionMode)&&(!loading)">

    </div> -->
  </div>
</template>

<script>
import { copyTextToClipboard } from "@/utils/copyLink.js"; // 根据你的项目路径调整导入路径
import VideoButton from "../VideoButton/VideoButton.vue";
export default {
  name: "VideoItem",
  components: {
    VideoButton,
  },
  props: {
    videoList: {
      type: Array,
      default: () => [],
    },
    isSelectionMode: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      default: 0, //默认为0，3-成片页面
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      list: this.videoList,
      addingTag: false,
      newTag: "",
      // selected: false,  // 添加选中状态
      isViewVideo: false,
    };
  },
  watch: {
    videoList: {
      handler(newVal) {
        this.list = newVal;
      },
      immediate: true,
    },
  },
  computed: {
    // loading() {
    //   return this.video.eraseVideoSubtitlesStatus === 1 || this.video.mergeVideoFaceStatus === 1;
    // },
    // statusMessage() {
    //   const statusMessages = [];
    //   const { eraseVideoSubtitlesStatus, mergeVideoFaceStatus } = this.video;
    //   if (eraseVideoSubtitlesStatus === 1 || mergeVideoFaceStatus === 1) {
    //     return this.$t('videoItem.generating');
    //   }
    //   if (eraseVideoSubtitlesStatus === 0 && mergeVideoFaceStatus === 0) {
    //     return ''; // 默认状态，不需要显示任何消息
    //   }
    //   // 拼接其他状态的消息
    //   if (eraseVideoSubtitlesStatus === 2) {
    //     statusMessages.push(this.$t('videoItem.removeSubtitleSuccess'));
    //   } else if (eraseVideoSubtitlesStatus === 3) {
    //     statusMessages.push(this.$t('videoItem.removeSubtitleFail'));
    //   }
    //   if (mergeVideoFaceStatus === 2) {
    //     statusMessages.push(this.$t('videoItem.aiFaceSwapSuccess'));
    //   } else if (mergeVideoFaceStatus === 3) {
    //     statusMessages.push(this.$t('videoItem.aiFaceSwapFail'));
    //   }
    //   return statusMessages.join(' ');
    // },
  },
  methods: {
    closeViewVideo() {
      this.isViewVideo = false;
    },
    viewVideo(row) {
      this.isViewVideo = true;
    },
    cellMouseLeave(row, column, cell, event) {
      if (this.isViewVideo) return;
      row.hover = false;
    },
    cellMouseEnter(row, column, cell, event) {
      row.hover = true;
    },
    nameClick(row) {
      if (row.documentType === "folder") {
        this.$emit("open-folder", row);
      } else {
      }
    },
    // 判断系统文件夹是否可选中
    checkSelectable(row) {
      if (this.userInfo.userType === "admin") return true;
      return row.type !== "system";
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id) || [];
      // this.labels = selection.map(item => item.label)
      // this.single = selection.length!==1
      // this.multiple = !selection.length
      this.$emit("select-multiple", selection);
    },
    isFailed(row) {
      const { eraseVideoSubtitlesStatus, mergeVideoFaceStatus } = row;
      return (
        (eraseVideoSubtitlesStatus === 3 && mergeVideoFaceStatus === 3) ||
        (eraseVideoSubtitlesStatus === 0 && mergeVideoFaceStatus === 3) ||
        (eraseVideoSubtitlesStatus === 3 && mergeVideoFaceStatus === 0)
      );
    },
    splitStatus(row) {
      const { splitVideoPartsStatus } = row;
      if (
        splitVideoPartsStatus === "QUEUING" ||
        splitVideoPartsStatus === "PROCESSING"
      ) {
        return this.$t("videoItem.clipping");
      } else if (splitVideoPartsStatus === "PROCESS_SUCCESS") {
        return this.$t("videoItem.clipSuccess");
      } else if (
        splitVideoPartsStatus === "PROCESS_FAILED" ||
        splitVideoPartsStatus === "TIMEOUT_FAILED" ||
        splitVideoPartsStatus === "LIMIT_RETRY_FAILED"
      ) {
        return this.$t("videoItem.clipFail");
      } else {
        return "";
      }
    },
    //拆条详情
    splitDetail(video) {
      this.$router.push({
        path: `/stripping/${video.id}`,
      });
    },
    //去发布
    publish(video) {
      this.$router.push({
        path: `/issueVideo/${video.id}`,
        query: { isProductId: true, url: video.url },
      });
    },

    copyLink(row) {
      copyTextToClipboard(row.url, this.$message);
    },
    // toggleSelection() {
    //   this.video.selected = !this.video.selected;
    //   console.log('this.video.selected',this.video.selected);
    //   this.$emit('toggle-selection', this.video.selected, this.video);
    // }
  },
};
</script>

<style scoped>
.left-section {
  position: relative;
}
.video-cover {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  object-fit: cover; /* 保持比例，裁剪多余部分 */
}
.material-name {
  color: #05082c;
}
.material-name:hover {
  color: #00aaff;
  cursor: pointer;
}

.middle-section {
  flex: 1;
  margin-left: 10px;
}

.video-name {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}
.video-description {
  font-size: 14px;
  margin-bottom: 5px;
  color: #666;
}

.font14 {
  font-size: 14px;
}

.video-tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.tag-input {
  width: auto;
  margin-right: 5px;
}

.video-created {
  color: #999;
}
.video-status {
  color: red;
}
.video-split {
  color: blue;
}

.right-section {
  display: flex;
}

.right-section .el-button {
  margin-left: 5px;
}

/* Hover样式 */
.el-button .el-tooltip__popper.is-dark {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.img-forder-list {
  width: 32px;
  height: 27px;
}
</style>

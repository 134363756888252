<template>
  <div
    class="video-grid-item"
    :class="{ 'selected': isSelectionMode && item.selected }"
    @click.stop="toggleSelection"
    @mouseover="mouseoverItem"
    @mouseleave="mouseleaveItem"
    :style="{ zIndex: item.hover ? 10 : 'auto' }"
  >
    <!-- 视频封面 -->
    <div
      class="left-section video-grid-bg flex-center"
      v-loading="loading"
    >
      <div
        v-if="item.fileType == 'image'"
        class="video-grid-cover "
      >
        <img
          :src="item.url"
          alt="image"
          class="video-grid-cover"
        />
        <!-- <div class="video-grid-type type-image">{{ $t('material.picture') }}</div> -->
      </div>
      <div
        v-else-if="item.fileType == 'audio'"
        class="video-grid-cover video-grid-bg flex-center"
      >
        <img
          src="../../assets/images/video/audio.png"
          alt=""
        >
        <!-- <div class="video-grid-type type-audio">{{ $t('material.audio') }}</div> -->
        <div
          class="video-audio-playing"
          v-if="isPlaying"
        >
          <img
            src="../../assets/images/video/audio-playing.gif"
            alt=""
          >
          试听中...
        </div>
        <!-- <svg-icon class="video-grid-cover" icon-class="audio"/> -->
      </div>
      <div
        v-else-if="item.fileType == 'text'"
        class="video-grid-cover video-grid-bg flex-center"
      >
        <img
          src="../../assets/images/video/text.png"
          alt=""
        >
        <!-- <div class="video-grid-type type-text">{{ $t('material.copywriting') }}</div> -->
      </div>
      <div
        v-else
        class="video-grid-cover"
      >
        <video
          :src="item.url"
          alt="video"
          class="video-grid-cover"
          muted
          playsinline
          loop
          preload="auto"
          ref="videoPlayer"
          @mouseenter="playVideo"
          @mouseleave="pauseVideo"
        />
        <!-- autoplay -->
        <span
          class="video-grid-duration"
          v-if="item.videoLength&&item.videoLength>0"
        >{{ secondsFormatTime(item.videoLength) }}</span>
        <div
          class="video-grid-clip"
          @click.stop="splitDetail(item)"
          v-if="!isFailed && splitStatus === $t('videoItem.clipSuccess')"
        >
          {{ $t('videoItem.clipDetails') }}
        </div>
        <!-- <div class="video-grid-type type-video">{{ $t('material.video') }}</div> -->
        <div
          class="video-grid-play"
          @click="viewMedia(item)"
        >
          <i class="iconfont icon-bofang1"></i>
        </div>
      </div>
      <el-tooltip
        :content="item.jobError"
        v-if="item.jobError"
      >
        <div
          class="controlsStatus"
          :class="[filterState(item)]"
          v-if="item.splitVideoPartsStatus || item.mergeVideoFaceStatus || item.eraseVideoSubtitlesStatus || item.jobStatus"
        >{{ statusMessage }}</div>
      </el-tooltip>
    </div>
    <!-- 视频信息 -->
    <div class="middle-section">
      <div class="material-title flex-row-center ">
        <i :class="['iconfont',getIcon(item.fileType), 'mr8','f-shrink']"></i>
        <div class="f-grow single-line-ellipsis">{{ item.fileType == 'text' ? item.title :item.name }}</div>
      </div>
      <el-tooltip
        v-if="getLabels(item.label)"
        :content="getLabels(item.label)"
      >
        <div class="video-grid-labels single-line-ellipsis">
          {{ getLabels(item.label) }}
        </div>
      </el-tooltip>
      <div
        v-else
        class="video-grid-labels"
      ></div>
      <!-- 操作按钮 -->
      <div v-if="item.hover && !loading">
        <div
          v-if="item.fileType == 'text' "
          class="flex-center right-section"
        >
          <el-tooltip :content="$t('view')">
            <i
              class="iconfont icon-a-xialajiantoubeifen3"
              @click.stop="showText"
            ></i>
          </el-tooltip>
          <el-tooltip
            :content="$t('edit')"
            v-if="item.type !== 'system' && !isSelectionMode "
          >
            <i
              class="iconfont icon-edit"
              @click.stop="$emit('edit-text',item)"
            ></i>
          </el-tooltip>
          <el-tooltip
            :content="$t('remove')"
            v-if="item.type !== 'system' && !isSelectionMode "
          >
            <i
              class="iconfont icon-delete"
              @click.stop="deleteVideo"
            ></i>
          </el-tooltip>
        </div>
        <div
          v-else
          class="flex-center right-section"
        >
          <el-tooltip
            :content="$t('videoManager.publish')"
            v-if="type == 3 && item.publishSuccessCount == 0 && !isSelectionMode "
          >
            <i
              class="iconfont icon-xingzhuangjiehe"
              @click.stop="publish(item)"
            ></i>
          </el-tooltip>
          <div v-if="item.fileType == 'image'">
            <el-tooltip
              :content="$t('view')"
              v-if="!isFailed"
            >
              <i
                class="iconfont icon-a-xialajiantoubeifen3"
                @click.stop="viewMedia(item)"
              ></i>
            </el-tooltip>
          </div>
          <div v-else-if="item.fileType == 'audio'">
            <el-tooltip
              :content="$t('file.filePreview')"
              v-if="!isFailed"
            >
              <i
                class="iconfont icon-erji"
                @click.stop="playAudio()"
              ></i>
            </el-tooltip>
          </div>
          <div v-else>
            <el-tooltip
              :content="$t('view')"
              v-if="!isFailed"
            >
              <i
                class="iconfont icon-a-xialajiantoubeifen3"
                @click.stop="$emit('view-text',item)"
              ></i>
            </el-tooltip>
          </div>
          <!-- <div  v-if="!isFailed"> -->
          <!-- <video-grid-button :type="1" :video="item" :tip="$t('file.filePreview')"> -->
          <!-- <template #customButton> -->
          <!-- <i class="el-icon-view" @click.stop="viewMedia(item)"></i> -->
          <!-- </template> -->
          <!-- </video-grid-button> -->
          <!-- </div> -->
          <el-tooltip
            :content="$t('edit')"
            v-if="!isFailed && item.type !== 'system' && !isSelectionMode "
          >
            <i
              class="iconfont icon-edit"
              @click.stop="$emit('edit-video',item)"
            ></i>
          </el-tooltip>
          <el-tooltip
            :content="$t('file.download')"
            v-if="!isFailed && item.type !== 'system' && !isSelectionMode "
          >
            <i
              class="iconfont icon-daoru"
              @click.stop="downloadFile(item.url)"
            ></i>
          </el-tooltip>
          <el-tooltip
            :content="$t('copyLink')"
            v-if="!isFailed && item.type !== 'system' && !isSelectionMode "
          >
            <i
              class="iconfont icon-lianjiebeidianjiliang"
              @click.stop="copyLink"
            ></i>
          </el-tooltip>
          <el-tooltip
            :content="$t('remove')"
            v-if="item.type !== 'system' && !isSelectionMode "
          >
            <i
              class="iconfont icon-delete"
              @click.stop="deleteVideo"
            ></i>
          </el-tooltip>
        </div>
      </div>
    </div>
    <VideoButtonGrid
      v-if="dialogVisible"
      :dialogVisible="dialogVisible"
      :video="mediaItem"
      :type="1"
      :tip="$t('file.filePreview')"
      @closeMedia="closeMediaFun"
    />
  </div>
</template>

<script>
import { copyTextToClipboard } from "@/utils/copyLink.js"; // 根据你的项目路径调整导入路径
import VideoButton from "../VideoButton/VideoButton.vue";
import VideoButtonGrid from "../VideoButtonGrid/VideoButton.vue";
export default {
  name: "VideoItem",
  components: {
    VideoButton,
    VideoButtonGrid,
  },
  props: {
    video: {
      type: Object,
      required: true,
    },
    isSelectionMode: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      default: 0, //默认为0，3-成片页面
    },
    currentAudioId: Number, // 父组件传入当前正在播放的音频 ID
  },
  data() {
    return {
      item: {},
      addingTag: false,
      newTag: "",
      dialogVisible: false,
      mediaItem: {},
      // selected: false,  // 添加选中状态
      isPlaying: false, // 本组件的播放状态
      currentAudio: null, // 当前播放的音频实例
    };
  },
  watch: {
    video: {
      handler(newVal, oldVal) {
        this.item = { ...newVal };
      },
      immediate: true,
      deep: true,
    },
    currentAudioId(newId) {
      // 如果当前音频不再是正在播放的，停止播放
      if (newId !== this.item.id) {
        this.isPlaying = false;
        this.stopAudio();
      }
    },
  },
  computed: {
    loading() {
      return (
        this.item.eraseVideoSubtitlesStatus === 1 ||
        this.item.mergeVideoFaceStatus === 1
      );
    },
    isFailed() {
      const { eraseVideoSubtitlesStatus, mergeVideoFaceStatus } = this.item;
      return (
        (eraseVideoSubtitlesStatus === 3 && mergeVideoFaceStatus === 3) ||
        (eraseVideoSubtitlesStatus === 0 && mergeVideoFaceStatus === 3) ||
        (eraseVideoSubtitlesStatus === 3 && mergeVideoFaceStatus === 0)
      );
    },
    statusMessage() {
      const statusMessages = [];
      const { eraseVideoSubtitlesStatus, mergeVideoFaceStatus, jobStatus } =
        this.item;

      if (eraseVideoSubtitlesStatus === 1 || mergeVideoFaceStatus === 1) {
        return this.$t("videoItem.generating");
      }
      if (eraseVideoSubtitlesStatus === 0 && mergeVideoFaceStatus === 0) {
        return ""; // 默认状态，不需要显示任何消息
      }
      // 拼接其他状态的消息
      if (eraseVideoSubtitlesStatus === 2) {
        statusMessages.push(this.$t("videoItem.removeSubtitleSuccess"));
      } else if (eraseVideoSubtitlesStatus === 3) {
        statusMessages.push(this.$t("videoItem.removeSubtitleFail"));
      }
      if (mergeVideoFaceStatus === 2) {
        statusMessages.push(this.$t("videoItem.aiFaceSwapSuccess"));
      } else if (mergeVideoFaceStatus === 3) {
        statusMessages.push(this.$t("videoItem.aiFaceSwapFail"));
      }
      if (jobStatus === "Executing") {
        statusMessages.push(this.$t("translate.inProgress"));
      } else if (jobStatus === "Finished") {
        statusMessages.push(this.$t("translate.success"));
      } else if (jobStatus === "Failed") {
        statusMessages.push(this.$t("translate.failure"));
      }
      return statusMessages.join(" ");
    },
    splitStatus() {
      const { splitVideoPartsStatus } = this.item;
      if (
        splitVideoPartsStatus === "QUEUING" ||
        splitVideoPartsStatus === "PROCESSING"
      ) {
        return this.$t("videoItem.clipping");
      } else if (splitVideoPartsStatus === "PROCESS_SUCCESS") {
        return this.$t("videoItem.clipSuccess");
      } else if (
        splitVideoPartsStatus === "PROCESS_FAILED" ||
        splitVideoPartsStatus === "TIMEOUT_FAILED" ||
        splitVideoPartsStatus === "LIMIT_RETRY_FAILED"
      ) {
        return this.$t("videoItem.clipFail");
      } else {
        return "";
      }
    },
  },
  beforeDestroy() {
    this.stopAudio();
  },
  methods: {
    getIcon(fileType) {
      if (fileType === "text") {
        return "icon-wenbensucai";
      } else if (fileType === "audio") {
        return "icon-yinpinsucai";
      } else if (fileType === "image") {
        return "icon-tupiansucai";
      } else {
        return "icon-shipinsucai";
      }
    },
    getLabels(labels) {
      return labels
        ? labels
            .split(",")
            .map((label) => `#${label}`)
            .join("    ")
        : "";
    },
    playAudio() {
      if (this.isPlaying) {
        // 如果当前正在播放，停止播放
        this.isPlaying = false;
        this.stopAudio();
      } else {
        // 否则，通知父组件更新当前播放的音频 ID
        this.$emit("play-audio", this.item.id);
        this.isPlaying = true;
        this.startAudio();
      }
    },
    startAudio() {
      this.currentAudio = new Audio(this.item.url); // 创建新的音频实例
      this.currentAudio.addEventListener("play", this.handlePlay); // 添加播放事件监听
      this.currentAudio.addEventListener("ended", this.handleEnded); // 添加播放完成事件监听
      this.currentAudio.play(); // 播放音频
    },
    stopAudio() {
      if (this.currentAudio) {
        this.currentAudio.pause(); // 停止当前播放的音频
        this.currentAudio.currentTime = 0; // 重置播放时间
        this.currentAudio.removeEventListener("play", this.handlePlay); // 移除播放事件监听
        this.currentAudio.removeEventListener("ended", this.handleEnded); // 移除播放完成事件监听
        this.currentAudio = null; // 清空当前音频实例
      }
    },
    handlePlay() {
      this.isPlaying = true;
    },
    handleEnded() {
      this.isPlaying = false;
    },
    playVideo() {
      const video = this.$refs.videoPlayer;
      video.play();
    },
    pauseVideo() {
      const video = this.$refs.videoPlayer;
      video.pause();
    },
    filterState(item) {
      const { eraseVideoSubtitlesStatus, mergeVideoFaceStatus, jobStatus } =
        item;
      if (eraseVideoSubtitlesStatus === 1 || mergeVideoFaceStatus === 1) {
        return "loadingTag";
      }
      if (eraseVideoSubtitlesStatus === 0 && mergeVideoFaceStatus === 0) {
        return "";
      }
      if (eraseVideoSubtitlesStatus === 2) {
        return "successTag";
      } else if (eraseVideoSubtitlesStatus === 3) {
        return "errorTag";
      }
      if (mergeVideoFaceStatus === 2) {
        return "successTag";
      } else if (mergeVideoFaceStatus === 3) {
        return "errorTag";
      }
      if (jobStatus === "Executing") {
        return "loadingTag";
      } else if (jobStatus === "Finished") {
        return "successTag";
      } else if (jobStatus === "Failed") {
        return "errorTag";
      }
    },
    closeMediaFun() {
      this.dialogVisible = false;
    },
    viewMedia(item) {
      this.mediaItem = item;
      this.dialogVisible = true;
    },
    //拆条详情
    splitDetail(video) {
      this.$router.push({
        path: `/stripping/${video.id}`,
      });
    },
    //去发布
    publish(video) {
      this.$router.push({
        path: `/issueVideo/${video.id}`,
        query: { isProductId: true, url: video.url },
      });
    },
    removeTag(index) {
      this.item.tags.splice(index, 1);
    },
    // confirmTag() {
    //   if (this.newTag) {
    //     this.video.tags.push(this.newTag);
    //   }
    //   this.addingTag = false;
    //   this.newTag = '';
    // },
    copyLink() {
      copyTextToClipboard(this.item.url, this.$message);
    },
    deleteVideo() {
      this.$emit("delete-video", this.item);
    },
    toggleSelection() {
      if (this.isSelectionMode) {
        this.item.selected = !this.item.selected;
        console.log("this.video.selected", this.item.selected);
        this.$emit("toggle-selection", this.item.selected, this.item);
      } else {
        // this.$emit('view-text',this.item)
        if (this.item.fileType === "text") {
          this.showText();
        } else if (this.item.fileType === "audio") {
          this.playAudio();
        } else {
          this.viewMedia(this.item);
        }
      }
    },
    showText() {
      this.$modal.alert(this.item.title, this.item.content);
    },
    mouseleaveItem() {
      if (this.dialogVisible === true) return;
      this.$set(this.item, "hover", false);
    },
    mouseoverItem() {
      if (this.dialogVisible === true) return;
      this.$set(this.item, "hover", true);
    },
  },
};
</script>

<style scoped lang="scss">
.video-grid-item {
  width: 100%;
  // height: 228px;
  margin: 0 29px 24px 0;
  border-radius: 12px;
  overflow: hidden;
  z-index: auto;
}
// .video-grid-item:hover {
//   box-shadow: 0 0 10px #ccc;
// }
.video-grid-item.selected {
  border: 1px solid #0156ff;
}
.left-section {
  position: relative;
  width: 100%;
  aspect-ratio: 5 / 3;
  // height: 144px;
  border-radius: 12px;
  min-height: 100px;
}
.controlsStatus {
  position: absolute;
  left: 5px;
  bottom: 5px;
  padding: 0 5px;
  /* width: 56px; */
  height: 20px;
  /* background: #006CFF; */
  border-radius: 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 10px;
  color: rgba(255, 255, 255, 0.9);
  line-height: 20px;
  text-align: center;
  font-style: normal;
  cursor: pointer;
}

.errorTag {
  background: #ff0000;
}
.successTag {
  background: #006cff;
}
.loadingTag {
  background: #ffbb00;
}
.video-grid-cover {
  width: 100%;
  aspect-ratio: 5 / 3; /* 设置宽高比为 5:3 */
  // min-height: 54px;
  // height: 144px;
  cursor: pointer;
  border-radius: 12px;
  object-fit: cover; /* 确保视频不会拉伸 */
  min-height: 100px;
}
.video-grid-bg {
  background-color: #ecf2fe;
}
.video-grid-duration {
  position: absolute;
  right: 8px;
  bottom: 8px;
  height: 20px;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 4px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  line-height: 20px;
  text-align: center;
  font-style: normal;
  padding: 0 6px;
}
.video-audio-playing {
  position: absolute;
  left: 8px;
  bottom: 8px;
  color: #0156ff;
  height: 20px;
  border-radius: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  font-style: normal;
  img {
    width: 20px;
  }
}
.video-grid-clip {
  position: absolute;
  right: 0px;
  top: 0px;
  background-color: #0156ff;
  border-radius: 12px;
  color: white;
  padding: 3px 6px;
  font-size: 10px;
}

.video-grid-type {
  position: absolute;
  left: 0px;
  top: 0px;
  color: white;
  padding: 0 6px;
  font-size: 10px;
  height: 24px;
  border-radius: 12px 4px 4px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.type-video {
  background-color: #00d147;
}
.type-image {
  background-color: #dc0266;
}
.type-audio {
  background-color: #6d20d3;
}
.type-text {
  background-color: #ff8e18;
}

.video-grid-play {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  i {
    color: #fff;
    font-size: 24px;
  }
}

.middle-section {
  height: 84px;
  padding-top: 8px;
  .material-title {
    height: 24px;
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    font-size: 14px;
    color: #000000;
    line-height: 24px;
    text-align: left;
    font-style: normal;
    i {
      font-size: 15px;
      color: #686868;
    }
  }
  .video-grid-labels {
    height: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #606060;
    line-height: 14px;
    text-align: left;
    font-style: normal;
    margin-top: 4px;
  }
  .right-section {
    width: 100%;
    margin-top: 12px;
    color: #7f7e7f;
    // border-radius: 0 12px 12px 0;
    // background: #f4f5ff;
    display: flex;
    justify-content: space-around;
    i {
      font-size: 16px;
      color: #7f7e7f;
      cursor: pointer;
    }
    i:hover {
      color: #0156ff;
    }
  }
}
</style>
